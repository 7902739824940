import * as styles from '../styles/timetable.module.scss';

import React, { useState } from 'react';

import Layout from '../components/Layout';
import ScrollToTopButton from '../components/ScrollToTopButton/ScrollToTopButton';
import SectionTitle from '../components/SectionTitle';
import Seo from '../components/SEO';
import Tab from '../components/Tab/Tab';
import TimetableEvents from '../components/TimetableEvents';
import Toggle from '../components/Toggle';
import { graphql } from 'gatsby';

const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

const Timetable = ({ data }) => {
  const [isFitness, setIsFitness] = useState(true);
  const [selectedGymIndex, setSelectedGymIndex] = useState(0);
  const allFitnessLessons = data.fitnessLessons.nodes;
  const allDanceLessons = data.danceLessons.nodes;

  const fitnessLessonsByLocation = allFitnessLessons.map(({ frontmatter: { lessons, location } }) => {
    const lessonsByDay = groupBy(lessons, 'day');

    return { location, lessons: lessonsByDay };
  });

  const danceLessonsByLocation = allDanceLessons.map(({ frontmatter: { lessons, location } }) => {
    const lessonsByDay = groupBy(lessons, 'day');

    return { location, lessons: lessonsByDay };
  });

  const handleDisciplineChange = () => {
    setIsFitness(!isFitness);
    setSelectedGymIndex(0);
  };

  const handleGymChange = (index) => {
    setSelectedGymIndex(index);
  };

  return (
    <Layout>
      <Seo title={'Pilart - Orari'} description={'Vieni a lezione con noi!'} />
      <SectionTitle title="ORARI" />
      <div className={styles.timetableWrapper}>
        <p className={styles.importantMessage}>
          La società si riserva la facoltà di variare giorni ed orari delle lezione per un miglior funzionamento delle attività
        </p>

        <Toggle callback={handleDisciplineChange} />

        <div className={styles.dropdownWrapper}>
          <h4>SELEZIONA UNA PALESTRA:</h4>

          <select
            name="locations"
            onChange={({ target }) => handleGymChange(target.value)}
            className={styles.dropdown}
            value={selectedGymIndex}
          >
            {isFitness
              ? fitnessLessonsByLocation.map(({ location }, index) => (
                  <option value={index} key={location}>
                    {location}
                  </option>
                ))
              : danceLessonsByLocation.map(({ location }, index) => (
                  <option value={index} key={location}>
                    {location}
                  </option>
                ))}
          </select>
        </div>

        {isFitness ? (
          <>
            <Tab eventOccured={isFitness} selectedGymIndex={selectedGymIndex}>
              {fitnessLessonsByLocation.map(({ location, lessons }, index) => (
                <Tab.TabPane key={`Tab-${index}`} tab={location}>
                  <TimetableEvents lessons={lessons} courseType="fitness" />
                </Tab.TabPane>
              ))}
            </Tab>
          </>
        ) : (
          <Tab eventOccured={isFitness} selectedGymIndex={selectedGymIndex}>
            {danceLessonsByLocation.map(({ location, lessons }, index) => (
              <Tab.TabPane key={`Tab-${index}`} tab={location}>
                <TimetableEvents lessons={lessons} courseType="dance" />
              </Tab.TabPane>
            ))}
          </Tab>
        )}
      </div>
      <ScrollToTopButton />
    </Layout>
  );
};

export default Timetable;

export const query = graphql`
  query TimetablePage {
    fitnessLessons: allMarkdownRemark(
      filter: { frontmatter: { discipline: { eq: "fitness" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      nodes {
        frontmatter {
          type
          location
          title
          lessons {
            lesson_id
            name
            startTime
            endTime
            teacherName
            teacherSlug
            day
          }
        }
        id
      }
    }

    danceLessons: allMarkdownRemark(
      filter: { frontmatter: { discipline: { eq: "dance" } } }
      sort: { order: ASC, fields: frontmatter___title }
    ) {
      nodes {
        frontmatter {
          type
          location
          title
          lessons {
            lesson_id
            name
            startTime
            endTime
            teacherName
            teacherSlug
            day
            ageGroup
          }
        }
        id
      }
    }
  }
`;
